import axios from 'axios';
import { URLS } from '../constants'
import UserService from '../services/UserService'
import WatsonService from '../services/WatsonService'
import store from '../store/store'
import router from '../router/index'

export default class AuthService {
  static auth = async (user, password, remember) => { 
    const params = {
      user: user, 
      password: password,
      remember: remember
    }
    try {
      const response = await axios.post(URLS.API_URL + URLS.AUTH_ROUTE, params, { withCredentials: true });
      store.commit("loadingoverlay/setLoadingOverlay", { show: true, opacity: 0.5, logo: 'logo-eme4.png', background: 'chat_eme4_lia_fundo_azul.png' });
      UserService.saveUser(response.data.user, remember);
      WatsonService.saveWatson(response.data.watson);
      router.push('home');
      return response;
    } catch (err) {
      store.commit("snackbar/setSnack", { message: err.response.data.error, color: 'error', duration: 4000 });
      return err;
    }
    } 

  static logout = async () => {
    try {
      await axios.post(URLS.API_URL + URLS.LOGOUT_ROUTE, null, { withCredentials: true });
      UserService.removeUser();
      WatsonService.removeWatson();
      router.push('/');
    } catch (err) {
      store.commit("snackbar/setSnack", { message: err.response.data.error, color: 'error', duration: 4000 });
    } 
  } 

  static validate = async () => {
    try {
      const response = await axios.post(URLS.API_URL + URLS.CHECK_ROUTE, null, { withCredentials: true });
      const remember = JSON.parse(localStorage.getItem('remember'));
      UserService.saveUser(response.data.user, remember);
      WatsonService.saveWatson(response.data.watson);
      return response;
    } catch (err) {
      console.error(err);
    }
  }
}
