<template>
  <v-overlay :style=" show && background ? `background-image: url(${require(`@/assets/${background}`)})` : ''" :value="show" :opacity="opacity">
    <v-card 
      color="transparent" 
      elevation="0" 
      class="text-center"
    >
      <v-img 
        v-if="logo"
        :src="require(`@/assets/${logo}`)"
        class="d-flex flex-column mb-4"
        width="140px"
      >
      </v-img>
      <v-card-title
        v-if="message"
        class="d-flex flex-column"
      >
        {{ message }}
      </v-card-title>
      <v-progress-circular
        indeterminate
        size="50"
        bottom
      ></v-progress-circular>
    </v-card>
  </v-overlay>
</template>

<script>
  export default {
    data: () => ({
      show: false,
      opacity: 0,
      message: '',
      background: '',
      logo: '',
    }),
    created: function() {
      this.$store.watch(
        state => state.loadingoverlay.loadingoverlay,
        () => {
          const show = this.$store.state.loadingoverlay.loadingoverlay.show
          if (show) {
            this.show = true
            this.opacity = this.$store.state.loadingoverlay.loadingoverlay.opacity
            this.message = this.$store.state.loadingoverlay.loadingoverlay.message
            this.background = this.$store.state.loadingoverlay.loadingoverlay.background
            this.logo = this.$store.state.loadingoverlay.loadingoverlay.logo
          } else {
            this.show = false
            this.opacity = 0
            this.message = ''
            this.background = ''
            this.logo = ''
          }
        }
      );
    },
  };
</script>
<style scoped>
  * {
    background-size: cover;
    background-position: center;
  }
</style>