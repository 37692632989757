<template>
  <v-app>
    <Snackbar />
    <Dialog />
    <LoadingOverlay />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/utils/Snackbar";
import Dialog from "./components/utils/Dialog";
import LoadingOverlay from "./components/utils/LoadingOverlay";
import { mapMutations } from 'vuex'
import CookiesService from './services/CookiesService'

export default {
  
  name: 'App',

  data: () => ({
  
  }),
  components: {
    Snackbar,
    Dialog,
    LoadingOverlay
  },
  mounted: function() {
    if(CookiesService.verifyCookieIsEnabled()) {
      this.setLoadingOverlay({
        show: true,
        opacity: 0.5,
        logo: 'logo-eme4.png',
        background: 'chat_eme4_lia_fundo_azul.png'
      })
    }
  },
  methods: {
    ...mapMutations('loadingoverlay', ['setLoadingOverlay']),
  }
};
</script>

<style>
  html { 
    overflow-y: unset !important ;
    scrollbar-width: none;
    -ms-overflow-style: none;
    max-height: 100vh !important;
  }
</style>
