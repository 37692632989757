import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        orange: '#ed6a1f',
        blue: '#024879',
        lightBlue: '#e5f0f3',
        lightGrey: '#f2f2f2'
      },
    },
  },
});
