import { make } from "vuex-pathify";

const getDefaultState = () => {
  return {
    loadingoverlay: {}
  };
};
const state = getDefaultState();

export default {
  namespaced: true,
  name: "loadingoverlay",
  state: state,
  mutations: {
    ...make.mutations(state),
    // ok, I am lazy!
    setLoadingOverlay(state, showLoadingOverlay) {
      state.loadingoverlay = { ...showLoadingOverlay };
    },
    removeLoadingOverlay(state, hiddeLoadingOverlay) {
      console.log(hiddeLoadingOverlay)
      state.loadingoverlay = { ...hiddeLoadingOverlay };
    }
  }
};