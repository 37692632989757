import { make } from "vuex-pathify";

const getDefaultState = () => {
  return {
    dialog: {}
  };
};
const state = getDefaultState();

export default {
  namespaced: true,
  name: "dialog",
  state: state,
  mutations: {
    ...make.mutations(state),
    // ok, I am lazy!
    setDialog(state, showDialog) {
      state.dialog = { ...showDialog };
    }
  }
};