import store from '../store/store'

export default class UserService {
  static getUser = () => {
    return store.getters.getUser
  }

  static saveUser = (user, remember) => {
    store.commit('setUser', user)
    store.commit('setLogged', true)
    localStorage.setItem('remember', remember)
  } 

  static removeUser = () => {
    store.commit('setUser', {})
    store.commit('setLogged', false)
    localStorage.removeItem('remember')
  }

  static hasUser = () => {
    return !!store.getters.getUser.id
  }
}