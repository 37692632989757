import axios from 'axios'
import { URLS } from '../constants'
import UserService from '../services/UserService'
import AuthService from '../services/AuthService'
import store from '../store/store'

export default class WatsonService {
  static init = async () => {
    const watson = this.getWatson()
    const user = UserService.getUser()
  
    const params = {
      input: {text:""}, 
      context: {
        user: user
      },
      workspaceId: watson.skillid
    }
    console.log(URLS.HTTP_URL)
    console.log(URLS.MESSAGE_ROUTE)
        
    try {
      const res = await axios.post(URLS.HTTP_URL + URLS.MESSAGE_ROUTE, params)
      return res.data
    } catch {
      store.commit("dialog/setDialog", { 
        header: 'Erro',
        message: 'Não foi possível iniciar o chat!', 
        color: 'white',
        persistent: true, 
        buttons: [
          {
            color: 'red',
            text: 'Sair',
            handler: async () => {
              store.commit("loadingoverlay/setLoadingOverlay",{ show: true, opacity: 0.5, message: 'Saindo'})
              await AuthService.logout()
            }
          }
        ]        
      })
    }
  } 

  static callAssistant = async (payload) => {
    const watson = this.getWatson()
    payload.workspaceId = watson.skillid
  
    try {
      const res = await axios.post(URLS.HTTP_URL + URLS.MESSAGE_ROUTE, payload)
      return res.data
    } catch {
      store.commit("dialog/setDialog", { 
        header: 'Erro',
        message: 'Não foi possível se comunicar com o Assistente!', 
        color: 'white',
        persistent: true, 
        buttons: [
          {
            color: 'red',
            text: 'Sair',
            handler: async () => {
              store.commit("loadingoverlay/setLoadingOverlay",{ show: true, opacity: 0.5, message: 'Saindo'})
              await AuthService.logout()
            }
          }
        ]        
      })
    }
  } 

  static saveWatson = (watson) => {
    store.commit('setWatson', watson)
  }

  static getWatson = () => {
    return store.getters.getWatson
  }
  
  static removeWatson = () => {
    localStorage.removeItem('watson')
  }
}
