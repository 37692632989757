<template>
 <v-dialog
      v-model="dialog"
      width="300"
      :persistent="persistent"
      :overlay-opacity="opacity"
    >
      <v-card :color="color">
        <v-card-title class="text-h5">
          <v-img
              max-width="23px"
              src="@/assets/logo_lia_03_32x32.png"
              class="mr-2"
          ></v-img>
          {{header}}
        </v-card-title>
        <v-card-text>
          {{message}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(button, index) in buttons"
            :key="index"
            :color="button.color"
            text
            @click="dialog = false;button.handler ? button.handler() : null"
          >
            {{button.text}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    data: () => ({
      show: false,
      header: "",
      message: "",
      color: "",
      dialog: false,
      buttons: [],
      persistent: false,
      opacity: 0.7
    }),
    created: function() {
      this.$store.watch(
        state => state.dialog.dialog,
        () => {
          const message = this.$store.state.dialog.dialog.message
          if (message) {
            this.show = true
            this.message = message
            this.header = this.$store.state.dialog.dialog.header
            this.color = this.$store.state.dialog.dialog.color
            this.buttons = this.$store.state.dialog.dialog.buttons
            this.persistent = this.$store.state.dialog.dialog.persistent
            this.opacity = this.$store.state.dialog.dialog.opacity
            this.dialog = true
            this.$store.commit("dialog/setDialog", {})
          }
        }
      );
    }
  };
</script>