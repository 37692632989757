<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout">
    {{message}}
  </v-snackbar>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        top: true,
        message: "",
        color: "",
        timeout: 3000
      };
    },
    created: function() {
      this.$store.watch(
        state => state.snackbar.snack,
        () => {
          const message = this.$store.state.snackbar.snack.message
          if (message) {
            this.show = true
            this.message = message
            this.timeout = this.$store.state.snackbar.snack.duration
            this.color = this.$store.state.snackbar.snack.color
            this.$store.commit("snackbar/setSnack", {})
          }
        }
      );
    }
  };
</script>