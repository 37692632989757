export const URLS = {
  API_URL: 'https://api.datainfo.inf.br/lia-api/public',
  HTTP_URL: window.location.protocol + '//' + window.location.host,
  // API_URL: 'http://192.168.60.53:8080/Lia/LiaV2/lia-backend/public',
  //  HTTP_URL: 'https://lia.homolog.eme4.inf.br',
  AUTH_ROUTE: '/auth',
  CHECK_ROUTE: '/check',
  LOGOUT_ROUTE: '/logout',
  MESSAGE_ROUTE: '/message'
};
