import Vuex from 'vuex'
import Vue from 'vue'
import snackbar from './modules/snackbar'
import dialog from './modules/dialog'
import loadingoverlay from './modules/loading-overlay'

Vue.use(Vuex)

const User = {
  state: () => ({ 
    user: {},
    logged: false
  }),
  mutations: { 
    setUser: (state, user) => {
      state.user = user
    },
    setLogged(state, logged) {
      state.logged = logged
    }  
  },
  getters: { 
    getUser: state => {
      return state.user 
    },
    getLogged: state => {
      return state.logged
    }
  }
}

const Watson = {
  state: () => ({ 
    watson: {}
  }),
  mutations: { 
    setWatson: (state, watson) => {
      state.watson = watson
    },  
  },
  getters: { 
    getWatson: state => {
      return state.watson
    } 
  }
}

export default new Vuex.Store({
  modules: {
    snackbar,
    dialog,
    loadingoverlay,
    User,
    Watson
  },

  state: () => ({ // = data
  }),

  getters: { // = computed properties
  },

  mutations: {
  }
})


