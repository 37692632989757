import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthService from '../services/AuthService'
import store from '../store/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash' //history
})

router.beforeEach(async (to, from, next) => {
  const logged = store.getters.getLogged
  const remember = JSON.parse(localStorage.getItem('remember'))

  if(to.name === 'Login')
    if(logged || (remember && await AuthService.validate())) 
      return next({ name: 'Home' })

  if(to.name !== 'Login' && !logged && !await AuthService.validate()) {
    return next({ name: 'Login' })
  }
  
  return next()
})

export default router
