import store from '../store/store'

export default class CookiesService {
  static verifyCookieIsEnabled = () => {
    if(!navigator.cookieEnabled) {
      store.commit("loadingoverlay/setLoadingOverlay",{ show: true, opacity: 0, background: 'chat_eme4_lia_fundo_azul.png'})
      store.commit("dialog/setDialog", { 
        header: 'Cookies',
        message: 'Os Cookies foram desativados no seu navegador. A Lia requer que eles estejam habilitados, ative-os e clique em OK para continuar!', 
        color: 'white',
        persistent: true, 
        opacity: 0,
        buttons: [
          {
            color: 'blue',
            text: 'OK',
            handler: async () => {
              window.location.reload()
            }
          }
        ]        
      })
    }
    return navigator.cookieEnabled
  }
}